var generic;
var site;

generic = generic || {};
site = site || {};

(function ($) {
  site.emailSignup = {
    $templateContainer: $(),
    initEmailSignup: function () {
      var $emailContainerNodes = this.$templateContainer;

      if ($emailContainerNodes.length < 0) {
        return null;
      }

      $emailContainerNodes.each(function () {
        var $emailContainerNode = $(this);
        var $emailForm = $('form', $emailContainerNode);
        var $emailSuccess = $('.js-email-signup__success', $emailContainerNode);
        var $emailError = $('.js-offers-email-signup-form__errors', $emailContainerNode);
        var $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailContainerNode);
        var $mobileInput = $('input[name="PHONE2"]', $emailContainerNode);

        $emailForm.submit(function (submitEvt) {
          // Transform string into array of form elements
          var params = {};
          submitEvt.preventDefault();
          $emailSuccess.add($emailError).addClass('hidden');
          $emailInput.removeClass('error');
          $mobileInput.removeClass('error');

          $emailForm.serializeArray().forEach(function (kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              $emailError.addClass('hidden');
              $.when($(document).triggerHandler('email_signup.success', [jsonRpcResponse])).then(
                function () {
                  // Show the success message if the email_signup.success handlers haven't already done
                  if ($('#colorbox').css('display') !== 'block') {
                    $.colorbox({ html: $emailSuccess.html() });
                  }
                }
              );
            },
            onFailure: function (jsonRpcResponse) {
              var errorObjectsArray = jsonRpcResponse.getMessages();

              $emailError.removeClass('hidden');
              generic.showErrors(errorObjectsArray, $emailError, $emailForm);
            }
          });
        });
      });
    },
  };

  Drupal.behaviors.emailSignupFormMxV2 = {
    attach: function (context) {
      site.emailSignup.$templateContainer = $('.js-email-signup', context);
      site.emailSignup.initEmailSignup();
    }
  };
})(jQuery);
